import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "../components/Container"

import "../styles/templates/client.scss"

class ClientPage extends Component {
  render() {
    const { data } = this.props
    const { homepageBannerBg, markdownRemark: post } = data

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          keywords={[
            `provalindonusa`,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
            `client`,
          ]}
          image={
            homepageBannerBg ? homepageBannerBg.childImageSharp.resize : null
          }
        />
        <div className="clientpage">
          <section className="clientpage__banner">
            <Container className="clientpage__banner-container">
              <h2>{post.frontmatter.title}</h2>
              <p>{`${post.frontmatter.year} - ${post.frontmatter.type}`}</p>
            </Container>
          </section>
          <section className="clientpage__story">
            <Container className="clientpage__story-container">
              <div
                className="clientpage__story-wrapper"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ClientPage

export const clientPageQuery = graphql`
  query($slug: String!) {
    homepageBannerBg: file(
      relativePath: { eq: "images/homepage-banner-bg.png" }
    ) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        year
        type
      }
    }
  }
`
